export const statusFunc: any = (str?: string) => {
  return {
    'to_be_confirmed': "待确认",
    'to_be_issued': "待发放",
    'confirmed': "已确认",
    'issued': "已发放",
  }
}

export const typeFunc: any = (str?: string) => {
  return {
    'basis': "基准提成",
    'other': "其他提成",
    'supplement': "销售补充",
  }
}


